import axios from "axios";
import uuidv4 from "uuid/v4";

import {
	API_URL,
	APP_ID,
	FACEBOOK_PIXEL_ID,
	GTM_KEY,
	MUNDIPAGG_PUBLIC_KEY,
	PLATAFORM_ID,
} from "../../config";
import {
	mapAddCardResponse,
	mapAddTicketCardRequestToApi,
	mapAddTicketCardResponse,
	mapAuthAPIResponse,
	mapCardsData,
	mapDeleteCardRequestToApi,
	mapDeleteCardResponse,
	mapFbLoginFormToAPI,
	mapGoogleLoginFormToAPI,
	mapLoginCreateData,
	mapLoginCreateRequest,
	mapMundipaggAccessTokenData,
	mapReceiptData,
	mapReceiptRequestNumber,
	mapReceiptStatusData,
	mapStatusAntiFraud,
	mapUserInfo,
	mapAntiFraudToApi,
	mapAntiFraudConfirm,
	mapDeliveryDates,
} from "../../domain/adapters";
import history from "../../history";
import {
	getCardBrand,
	getCardTypeByBrand,
	onlyNumbers,
	utmParamsUtils,
	withAuthHeader,
} from "../../infra/utils";
import { addressActions, signUp, user } from "../../state";
import { initialState } from "../../state/reducers/main";

const getDeviceToken = () => {
	const savedToken = localStorage.getItem("deviceToken");
	if (savedToken) return savedToken;

	const newToken = uuidv4();
	localStorage.setItem("deviceToken", newToken);
	return newToken;
};

const apiService = axios.create({
	responseType: "json",
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
		Idioma: "pt-br",
		Plataforma: PLATAFORM_ID,
		Versao: "3.12",
		DeviceToken: getDeviceToken(),
		AplicativoId: APP_ID,
		OneSignalPlayerId: "",
		Agent: "Ecommerce",
	},
});

apiService.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const status = error.response.status;

		switch (status) {
			case 401:
				localStorage.setItem("main", JSON.stringify(initialState));
				history.push(`/`);
			case 400:
				throw new Error("Bab Request");
			case 500:
				throw new Error("Internal Server Error");
			default:
				return Promise.reject(error);
		}
	}
);

const mainModule = "main";

export const actionTypes = {
	SET_SHOW_SIGN_UP: "SET_SHOW_SIGN_UP",
	SET_THING: "SET_THING",
	SET_DEVICE: "SET_DEVICE",
	SET_ADDITONALS: "SET_ADDITONALS",
	SET_OPEN_CART: "SET_OPEN_CART",
	SET_CARD: "SET_CARD",
	SET_SAVED_CARD: "SET_SAVED_CARD",
	SET_DELETE_CARD: "SET_DELETE_CARD",
	SET_CATEGORIES: "SET_CATEGORIES",
	SET_ERROR: "SET_ERROR",
	SET_CARD_NAME: "SET_CARD_NAME",
	SET_CARD_NUMBER: "SET_CARD_NUMBER",
	SET_CARD_DATE: "SET_CARD_DATE",
	SET_CARD_CPF: "SET_CARD_CPF",
	SET_CARD_BRAND_ID: "SET_CARD_BRAND_ID",
	SET_CARD_CVV: "SET_CARD_CVV",
	SET_CARD_SAVED: "SET_CARD_SAVED",
	SET_CONTACT_US: "SET_CONTACT_US",
	SET_COUPONS: "SET_COUPONS",
	SET_COUPON: "SET_COUPON",
	SET_MODALITY: "SET_MODALITY",
	SET_ORDER: "SET_ORDER",
	SET_ORDER_HISTORY: "SET_ORDER_HISTORY",
	SET_ORDERS: "SET_ORDERS",
	SET_ORDER_FINISH_DATA: "SET_ORDER_FINISH_DATA",
	SET_CART: "SET_CART",
	SET_CART_COUPON: "SET_CART_COUPON",
	SET_PARAMS: "SET_PARAMS",
	SET_QUANTITY: "SET_QUANTITY",
	SET_LOGIN: "SET_LOGIN",
	SET_LOADING: "SET_LOADING",
	SET_SEARCH: "SET_SEARCH",
	SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
	/* SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT', */
	SET_SIGN_IN: "SET_SIGN_IN",
	SET_STORE: "SET_STORE",
	SET_STORE_ID: "SET_STORE_ID",
	SET_STORE_FEES: "SET_STORE_FEES",
	SET_STORES: "SET_STORES",
	SET_USER_CARD: "SET_USER_CARD",
	SET_USER_CARD_CVV: "SET_USER_CARD_CVV",
	SET_USER_CARDS: "SET_USER_CARDS",
	SET_USER_HISTORY: "SET_USER_HISTORY",
	SET_USER_ORDER_HISTORY: "SET_USER_ORDER_HISTORY",
	SET_NOTIFICATION: "SET_NOTIFICATION",
	SET_RECEIPT: "SET_RECEIPT",
	SET_RECEIPT_STATUS: "SET_RECEIPT_STATUS",
	SET_USER_SCHEDULING: "SET_USER_SCHEDULING",
	ADD_PRODUCT: "ADD_PRODUCT",
	REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
	REMOVE_SELECTED_PRODUCT_ADDITIONAL: "REMOVE_SELECTED_PRODUCT_ADDITIONAL",
	REMOVE_PRODUCT: "REMOVE_PRODUCT",
	REMOVE_SELECTED_CATEGORY: "REMOVE_SELECTED_CATEGORY",
	REMOVE_SELECTED_OLD_CATEGORY: "REMOVE_SELECTED_OLD_CATEGORY",
	REMOVE_SELECTED_OLD_PRODUCT: "REMOVE_SELECTED_OLD_PRODUCT",
	SET_SEARCH_PRODUCT_CATEGORY: "SET_SEARCH_PRODUCT_CATEGORY",
	SET_PRODUCT_CATEGORY: "SET_PRODUCT_CATEGORY",
	SET_PAGINATION: "SET_PAGINATION",
	SET_SCREEN_HEIGHT: "SET_SCREEN_HEIGHT",
	REMOVE_SCREEN_HEIGHT: "REMOVE_SCREEN_HEIGHT",
	SET_RECEIPT_NOTIFICATION: "SET_RECEIPT_NOTIFICATION",
	SET_CARD_BIRTH: "SET_CARD_BIRTH",
	SET_SCHEDULING: "SET_SCHEDULING",
	SET_METHOD_LOGIN: "SET_METHOD_LOGIN",
	SET_PRODUCTS_REMOVED: "SET_PRODUCTS_REMOVED",
	SET_STORE_SETTINGS: "SET_STORE_SETTINGS",
	SET_SCROLL: "SET_SCROLL",
	SET_URL_PARAMETERS: "SET_URL_PARAMETERS",
	SET_ANTIFRAUD_CHECK: "SET_ANTIFRAUD_CHECK",
	SET_ANTIFRAUD_CONFIRMATION: "SET_ANTIFRAUD_CONFIRMATION",
	SET_ANTIFRAUD: "SET_ANTIFRAUD",
	SET_SELECTED_COUPON: "SET_SELECTED_COUPON",
	SET_DIRECT_ACCESS: "SET_DIRECT_ACCESS",
	SET_GTM: "SET_GTM",
	SET_PIXEL: "SET_PIXEL",
	SET_SIGN_UP: "SET_SIGN_UP",
	SET_LOGOUT: "SET_LOGOUT",
	SET_AVALIABLE_DATES: "SET_AVALIABLE_DATES",
	SET_SELECTED_DELIVERY_DATE: "SET_SELECTED_DELIVERY_DATE",
};

function handleError(...args) {
	const e = args[0];
	const customMessage = args[2];
	const callback = args[3];

	return (dispatch) => {
		dispatch(setLoading({ clean: true }));

		const error = JSON.parse(JSON.stringify(e));
		const { message } = error;

		const extractCode = message && message.match(/[0-9]/g);

		const code = Number(extractCode && extractCode.join(""));

		if (code === 401) {
			dispatch(setLogin(null));
			dispatch(
				setNotification({
					type: "warning",
					message:
						customMessage || "Identificação inválida. Faça login novamente.",
					callback,
				})
			);
		}
	};
}

export function handleGTM(data) {
	return (dispatch) => {
		if (GTM_KEY && GTM_KEY !== "false") {
			// ('Event: ', data)
		}

		if (GTM_KEY && GTM_KEY !== "false" && !data) {
			dispatch(
				setGTM({
					events: null,
				})
			);

			return;
		}

		if (GTM_KEY && GTM_KEY !== "false" && data && data.unique === true) {
			const gtm = dispatch(getGTM());

			const { events } = gtm;

			const { eventId } = data;

			const hasCalled =
				(events &&
					events.filter((filteredItem) => filteredItem.event === eventId)[0]) ||
				false;

			if (!hasCalled) {
				const newData = {
					...data,
				};

				delete newData.eventId;
				delete newData.unique;

				const newEvents = [...(events && events.length ? events : []), data];

				const newGTM = {
					...gtm,
					events: newEvents,
				};

				dispatch(setGTM(newGTM));

				const utmParams = utmParamsUtils.getUTMParams();

				window.dataLayer.push({ ...utmParams, ...newData });

				return;
			}
		}

		if (GTM_KEY && GTM_KEY !== "false" && data) {
			const gtm = dispatch(getGTM());

			const { events } = gtm;

			const newEvents = [...(events && events.length ? events : []), data];

			const newGTM = {
				...gtm,
				events: newEvents,
			};

			dispatch(setGTM(newGTM));

			const utmParams = utmParamsUtils.getUTMParams();
			window.dataLayer.push({ ...utmParams, ...data });
		}
	};
}

export function handlePixel(data) {
	return (dispatch) => {
		if (FACEBOOK_PIXEL_ID && FACEBOOK_PIXEL_ID !== "false") {
			// ('Event: ', data)
		}

		if (FACEBOOK_PIXEL_ID && FACEBOOK_PIXEL_ID !== "false" && !data) {
			dispatch(
				setPixel({
					events: null,
				})
			);

			return;
		}

		if (
			FACEBOOK_PIXEL_ID &&
			FACEBOOK_PIXEL_ID !== "false" &&
			data &&
			data.unique === true
		) {
			const pixel = dispatch(setPixel());

			const { events } = pixel;

			const { eventId } = data;

			const hasCalled =
				(events &&
					events.filter((filteredItem) => filteredItem.event === eventId)[0]) ||
				false;

			if (!hasCalled) {
				const newData = {
					...data,
				};

				delete newData.eventId;
				delete newData.unique;

				const newEvents = [...(events && events.length ? events : []), data];

				const newPixel = {
					...pixel,
					events: newEvents,
				};

				dispatch(setPixel(newPixel));

				console.log(newData);
				if (typeof window !== "undefined") {
					if (window.fbq != null) {
						window.fbq("track", newData.eventName, newData.body);
					}
				}
				return;
			}
		}

		if (FACEBOOK_PIXEL_ID && FACEBOOK_PIXEL_ID !== "false" && data) {
			const pixel = dispatch(setPixel());

			const { events } = pixel;

			const newEvents = [...(events && events.length ? events : []), data];

			const newPixel = {
				...pixel,
				events: newEvents,
			};

			dispatch(setPixel(newPixel));

			console.log(data);

			if (typeof window !== "undefined") {
				if (window.fbq != null) {
					window.fbq("track", data.eventName, data.body);
				}
			}
		}
	};
}

export const isDelivery = () => (dispatch, getState) => {
	const modalityId = getState().main.modality && getState().main.modality.id;

	return modalityId === 4;
};

export const isPickup = () => (dispatch, getState) => {
	const modalityId = getState().main.modality && getState().main.modality.id;

	return modalityId === 2;
};

export const getGTM = () => (dispatch, getState) => {
	const gtm = getState().main.gtm;

	return gtm;
};

export const getStore = () => (dispatch, getState) => {
	const store = getState().store.store;

	return store;
};

export const getLogin = () => (dispatch, getState) => {
	const login = getState().main.login;

	return login;
};

export const setHighlights = (highlights) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_HIGHLIGHTS,
		payload: highlights,
	});
};

export const setDirectAccess = (directAccess) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_DIRECT_ACCESS,
		payload: directAccess,
	});
};

export const setOpenCart = (open) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_OPEN_CART,
		payload: open,
	});
};

export const setParams = (params) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_PARAMS,
		payload: params,
	});
};

export const setURLParameters = (parameters) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_URL_PARAMETERS,
		payload: parameters,
	});
};

export const setStoresPagination = (pagination) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_PAGINATION,
		payload: pagination,
	});
};

export const setStores = (stores) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_STORES,
		payload: stores,
	});
};

export const setSignIn = (signIn) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SIGN_IN,
		payload: signIn,
	});
};

export const setCategories = (categories) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CATEGORIES,
		payload: categories,
	});
};

export const setProductCategory = (products) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_PRODUCT_CATEGORY,
		payload: products,
	});
};

export const setScreenHeight = (screen) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SCREEN_HEIGHT,
		payload: screen,
	});
};

export const removeScreenHeight = () => async (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_SCREEN_HEIGHT,
	});
};

export const setUserHistory = (userHistory) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_HISTORY,
		payload: userHistory,
	});
};

export const setUserOrderHistory = (orderHistory) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_ORDER_HISTORY,
		payload: orderHistory,
	});
};

export const setCard = (card) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD,
		payload: card,
	});
};

export const setCardName = (cardName) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_NAME,
		payload: cardName,
	});
};

export const setCardNumber = (cardNumber) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_NUMBER,
		payload: cardNumber,
	});
};

export const setCardDate = (cardDate) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_DATE,
		payload: cardDate,
	});
};

export const setCardBirth = (cardBirth) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_BIRTH,
		payload: cardBirth,
	});
};

export const setGTM = (gtm) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_GTM,
		payload: gtm,
	});
};

export const setPixel = (pixel) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_PIXEL,
		payload: pixel,
	});
};

export const setCardCPF = (cardCPF) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_CPF,
		payload: cardCPF,
	});
};

export const setCardBrandId = (cardBrandId) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_BRAND_ID,
		payload: cardBrandId,
	});
};

export const setCardCVV = (cardCVV) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CARD_CVV,
		payload: cardCVV,
	});
};

export const setNotification = (notification) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_NOTIFICATION,
		payload: notification,
	});
};

export const setReceiptNotification =
	(receiptNotification) => async (dispatch) => {
		dispatch({
			type: actionTypes.SET_RECEIPT_NOTIFICATION,
			payload: receiptNotification,
		});
	};

export const setCEP = (cep) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CEP,
		payload: cep,
	});
};

export const setUserCard = (card) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_CARD,
		payload: card,
	});
};

export const setCVV = (cvv) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_CARD_CVV,
		payload: cvv,
	});
};

export const setLoading = (loading) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_LOADING,
		payload: loading,
	});
};

export const setMethodAccess = (method) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_METHOD_LOGIN,
		payload: method,
	});
};

export const setProductsRemoved = (product) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_PRODUCTS_REMOVED,
		payload: product,
	});
};

export const setThing = (thing) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_THING,
		payload: thing,
	});
};

export const setDevice = (device) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_DEVICE,
		payload: device,
	});
};

export const setUserCards = (userCards) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_CARDS,
		payload: userCards,
	});
};

export const setReceipt = (receipt) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_RECEIPT,
		payload: receipt,
	});
};

export const setStore = (store) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_STORE,
		payload: store,
	});
};

export const setStoreId = (storeId) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_STORE_ID,
		payload: storeId,
	});
};

export const setStoreFees = (storeFees) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_STORE_FEES,
		payload: storeFees,
	});
};

export const setOrder = (order) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_ORDER,
		payload: order,
	});
};

export const setModality = (modality) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_MODALITY,
		payload: modality,
	});
};

export const setSelectedCategory = (category) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SELECTED_CATEGORY,
		payload: category,
	});
};

export const setSearch = (search) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SEARCH,
		payload: search,
	});
};

export const setSearchProductCategory = (search) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SEARCH_PRODUCT_CATEGORY,
		payload: search,
	});
};

export const setCart = (cart) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_CART,
		payload: cart,
	});
};

export const setShowSignUp = (showSignUp) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SHOW_SIGN_UP,
		payload: showSignUp,
	});
};

export const removeSelectedProduct = () => async (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_SELECTED_PRODUCT,
	});
};

export const removeSelectedOldCategory = () => async (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_SELECTED_OLD_CATEGORY,
	});
};

export const removeSelectedOldProduct = () => async (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_SELECTED_OLD_PRODUCT,
	});
};

export const removeSelectedProductsCategories = () => async (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_SELECTED_CATEGORY,
	});
};

export const addProduct = (product) => async (dispatch) => {
	dispatch({
		type: actionTypes.ADD_PRODUCT,
		payload: product,
	});
};

export const setQuantity = (product) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_QUANTITY,
		payload: product,
	});
};

export const setLogin = (login) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_LOGIN,
		payload: login,
	});
};

export const setUserScheduling = (userScheduling) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_USER_SCHEDULING,
		payload: userScheduling,
	});
};

export const removeProduct = (productIndex) => async (dispatch) => {
	dispatch({
		type: actionTypes.REMOVE_PRODUCT,
		payload: productIndex,
	});
};

export const removeSelectedProductAdditional =
	(productAdditional) => async (dispatch) => {
		dispatch({
			type: actionTypes.REMOVE_SELECTED_PRODUCT_ADDITIONAL,
			payload: productAdditional,
		});
	};

export const setSelectedDeliveryDate = (date) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SELECTED_DELIVERY_DATE,
		payload: date,
	});
};

export const fetchUser = (callback) => async (dispatch, getState) => {
	try {
		const loading = getState().loading.loading;

		if (loading && loading.indexOf("user") > -1) {
			return false;
		}

		dispatch(setLoading("user"));

		const url = `usuarios/obter`;
		const result = await apiService.get(url, withAuthHeader(mainModule));
		const mappedResult = mapUserInfo(result.data);

		const stateAddress = getState().address.address || {};

		if (mappedResult && mappedResult.name) {
			dispatch({
				type: actionTypes.SET_USER_NAME,
				payload: mappedResult.name,
			});
		}

		dispatch(
			addressActions.setAddress({
				...stateAddress,
				last: true,
			})
		);

		if (
			mappedResult &&
			mappedResult.address &&
			mappedResult.address.id &&
			mappedResult.address.main
		) {
			dispatch(
				addressActions.setAddress({
					...stateAddress,
					main: mappedResult.address.main,
					neighborhood: mappedResult.address.neighborhood,
					id: mappedResult.address.id,
					city: mappedResult.address.city,
					latitude: mappedResult.address.latitude,
					longitude: mappedResult.address.longitude,
					last: true,
				})
			);

			return;
		}

		if (
			mappedResult &&
			mappedResult.address &&
			!mappedResult.address.id &&
			!mappedResult.address.main
		) {
			dispatch(
				addressActions.setAddress({
					...stateAddress,
					main: stateAddress.backup,
					neighborhood: mappedResult.address.neighborhood,
					id: mappedResult.address.id,
					city: mappedResult.address.city,
					latitude: mappedResult.address.latitude,
					longitude: mappedResult.address.longitude,
					last: true,
				})
			);

			return;
		}
	} catch (e) {
		console.log(e);
	} finally {
		callback && callback();

		dispatch(setLoading({ item: "user", delete: true }));
	}
};

export const fetchStoreFees = (callback) => async (dispatch, getState) => {
	const callbackErrorFilter =
		callback &&
		callback.filter((filteredItem) => filteredItem.type === "error");
	const callbackError =
		callbackErrorFilter &&
		callbackErrorFilter[0] &&
		callbackErrorFilter[0].callback;
	const callbackSuccessFilter =
		callback &&
		callback.filter((filteredItem) => filteredItem.type === "success");
	const callbackSuccess =
		callbackSuccessFilter &&
		callbackSuccessFilter[0] &&
		callbackSuccessFilter[0].callback;

	dispatch(setStoreFees(null));

	try {
		const stateAddress = getState().address.address || {};
		const { latitude, longitude } = getState().address || {};
		const { main, number, neighborhood, city, state, cep } = stateAddress;
		const storeId =
			(getState().main.store && getState().main.store.id) ||
			(getState().main.receipt &&
				getState().main.receipt.store &&
				getState().main.receipt.store.id);
		const selectedStoreId =
			(getState().store && getState().store.selectedStoreId) || "";

		if (!main) {
			return false;
		}

		dispatch(setLoading("store-fees"));

		const fullAddress = `${main}, ${number} - ${neighborhood}, ${city} - ${state}, ${cep}`;

		const deliveryUrl = `Estabelecimentos/ObterTaxaEntrega/${
			selectedStoreId || storeId
		}${`?cep=${cep}&numero=${number}&latitude=${latitude}&longitude=${longitude}&endereco=${fullAddress}`}`;
		const deliveryResult = await apiService.get(
			deliveryUrl,
			withAuthHeader(mainModule)
		);

		const modalityPickup = deliveryResult.data.ModalidadesRetirada.filter(
			(filteredItem) => filteredItem.ModalidadeRetirada === 2
		);
		const modalityDelivery = deliveryResult.data.ModalidadesRetirada.filter(
			(filteredItem) => filteredItem.ModalidadeRetirada === 4
		);

		const pickupMinimumValue =
			modalityPickup &&
			modalityPickup[0] &&
			modalityPickup[0].ValorMinimoPedido;
		const deliveryMinimumValue =
			modalityDelivery &&
			modalityDelivery[0] &&
			modalityDelivery[0].ValorMinimoPedido;

		const pickupFee =
			modalityPickup && modalityPickup[0] && modalityPickup[0].Valor;
		const deliveryFee =
			modalityDelivery && modalityDelivery[0] && modalityDelivery[0].Valor;

		const deliveryTime = deliveryResult.data.EstimativaEntrega;
		const minForFreeShipping = deliveryResult.data.ValorMinimoPedidoFreteGratis;

		dispatch(
			setStoreFees({
				fees: {
					loaded: true,
				},
				pickupMinimumValue,
				deliveryMinimumValue,
				pickupFee,
				deliveryFee,
				deliveryTime,
				minForFreeShipping,
			})
		);

		callbackSuccess && callbackSuccess();

		return;
	} catch (e) {
		console.log(e);

		dispatch(setStoreFees(null));

		callbackError && callbackError();
	} finally {
		dispatch(setLoading({ item: "store-fees", delete: true }));
	}
};

export const fetchReceiptStatus =
	({ checkoutId, modality }) =>
	async (dispatch) => {
		try {
			const url =
				modality === 1 || modality === 2
					? `Pedidos/ObterStatusPedidoBalcao?comandaId=${checkoutId}`
					: `Pedidos/ObterStatusDelivery?comandaId=${checkoutId}`;
			const result = await apiService.get(url, withAuthHeader(mainModule));
			const mappedResult = mapReceiptStatusData(result.data);

			dispatch({
				type: actionTypes.SET_RECEIPT_STATUS,
				payload: mappedResult,
			});

			dispatch(setLoading({ item: "receipt-status", delete: true }));
		} catch (e) {
			console.log(e);

			dispatch(setLoading({ item: "receipt-status", delete: true }));
		}
	};

export const setAntiFraudCheck =
	({ check }) =>
	async (dispatch) => {
		dispatch({
			type: actionTypes.SET_ANTIFRAUD_CHECK,
			payload: check,
		});
	};

export const setAntiFraudConfirmation =
	({ antiFraud }) =>
	async (dispatch) => {
		try {
			const url = `/AvisoAntifraude/Confirmar`;
			const dto = mapAntiFraudToApi(antiFraud);
			const apiResult = await apiService.post(
				url,
				dto,
				withAuthHeader(mainModule)
			);
			const response = mapAntiFraudConfirm(apiResult.data);

			if (response.success) {
				dispatch({
					type: actionTypes.SET_ANTIFRAUD,
					payload: {},
				});

				return response;
			} else {
				dispatch({
					type: actionTypes.SET_ANTIFRAUD,
					payload: {},
				});

				return response;
			}
		} catch (e) {
			console.log(e);
		}
	};

export const fetchStatusAntiFraud =
	({ comandaId }) =>
	async (dispatch) => {
		try {
			const url = `AvisoAntifraude/VerificarDisponibilidade?comandaId=${comandaId}`;
			const result = await apiService.get(url, withAuthHeader(mainModule));
			const mappedResult = mapStatusAntiFraud(result.data);

			dispatch({
				type: actionTypes.SET_ANTIFRAUD,
				payload: mappedResult,
			});
		} catch (e) {
			console.log(e);
		}
	};

export const fetchRequestNumber =
	({ checkoutId }) =>
	async (dispatch, getState) => {
		try {
			const receipt = getState().main.receipt;

			const url = `Pedidos/ObterNumeroChamada?comandaId=${checkoutId}`;
			const apiResult = await apiService.get(url, withAuthHeader(mainModule));
			const response = mapReceiptRequestNumber(apiResult.data);

			const { requestNumber } = response;

			dispatch({
				type: actionTypes.SET_RECEIPT,
				payload: {
					...receipt,
					requestNumber,
				},
			});

			dispatch(setLoading({ item: "receipt-request-number", delete: true }));
		} catch (e) {
			console.log(e);

			dispatch(setLoading({ item: "receipt-request-number", delete: true }));
		}
	};

export const fetchReceipt = () => async (dispatch, getState) => {
	try {
		const orderId =
			(getState().main.order && getState().main.order.id) ||
			(getState().main.order &&
				getState().main.order.finishData &&
				getState().main.order.finishData.vqpOrderId);
		const partnerOrderId =
			getState().main.params && getState().main.params.partnerOrderId;

		dispatch(setLoading("receipt"));

		const url = `Pedidos/ObterComprovante?${
			partnerOrderId
				? `parceiroPedidoId=${partnerOrderId}`
				: `comandaId=${orderId}`
		}`;
		const result = await apiService.get(url, withAuthHeader(mainModule));
		const mappedResult = mapReceiptData(result.data);

		dispatch({
			type: actionTypes.SET_RECEIPT,
			payload: mappedResult,
		});

		dispatch(setLoading(""));
	} catch (e) {
		console.log(e);

		dispatch({
			type: actionTypes.SET_RECEIPT,
			payload: null,
		});

		dispatch(setLoading(""));
	}
};

export const fetchCards = (callback) => async (dispatch, getState) => {
	const callbackError = callback && callback[0];

	try {
		dispatch(setLoading({ item: "cards", delete: true }));

		const storeId = getState().main.store && getState().main.store.id;
		const loading = getState().loading.loading;
		const modalityId = getState().main.modality && getState().main.modality.id;

		if (loading && loading.indexOf("cards") > -1) {
			return false;
		}

		dispatch(setLoading("cards"));

		const url =
			modalityId === 4
				? `Cartoes/Listar/${storeId}?delivery=true`
				: `Cartoes/Listar/${storeId}`;
		const result = await apiService.get(url, withAuthHeader(mainModule));
		const mappedResult = mapCardsData(result.data);

		if (mappedResult.success) {
			dispatch(setNotification({}));

			dispatch({
				type: actionTypes.SET_USER_CARDS,
				payload: mappedResult,
			});

			dispatch(setLoading({ item: "cards", delete: true }));

			return mappedResult;
		}

		dispatch({
			type: actionTypes.SET_USER_CARDS,
			payload: null,
		});

		dispatch(setLoading({ item: "cards", delete: true }));
	} catch (e) {
		dispatch(
			handleError(
				e,
				"payment",
				"Não foi possível listar seus cartões cadastrados. Volte e tente novamente.",
				callbackError
			)
		);

		dispatch({
			type: actionTypes.SET_USER_CARDS,
			payload: null,
		});
	}
};

export const fetchDeliveryDates = (callback) => async (dispatch, getState) => {
	const callbackError = callback && callback[0];

	try {
		dispatch(setLoading({ item: "deliveryDates", delete: true }));

		const storeId = getState().main.store && getState().main.store.id;
		const loading = getState().loading.loading;

		if (loading && loading.indexOf("deliveryDates") > -1) {
			return false;
		}

		dispatch(setLoading("deliveryDates"));

		const url = `Agendamentos/Obter?EstabelecimentoId=${storeId}&ModalidadeRetiradaId=4`;
		const result = await apiService.get(url, withAuthHeader(mainModule));

		const mappedResult = mapDeliveryDates(result.data);

		if (mappedResult.success) {
			dispatch(setNotification({}));

			dispatch({
				type: actionTypes.SET_AVALIABLE_DATES,
				payload: mappedResult,
			});

			dispatch(setLoading({ item: "deliveryDates", delete: true }));

			return mappedResult;
		}

		dispatch({
			type: actionTypes.SET_AVALIABLE_DATES,
			payload: null,
		});

		dispatch(setLoading({ item: "deliveryDates", delete: true }));
	} catch (e) {
		dispatch(
			handleError(
				e,
				"payment",
				"Erro ao carregar os dias para agendamento!",
				callbackError
			)
		);

		dispatch({
			type: actionTypes.SET_AVALIABLE_DATES,
			payload: null,
		});
	}
};

const fetchCardCreateToken = (card) => async () => {
	const url = `https://api.pagar.me/core/v5/tokens?appId=${MUNDIPAGG_PUBLIC_KEY}`;
	const result = await axios.post(
		url,
		{
			type: "card",
			card,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		}
	);
	const mappedResult = mapMundipaggAccessTokenData(result.data);

	return mappedResult;
};

const postCardWithToken =
	({ cardToken, cardType, cardBrand }) =>
	async () => {
		const url = `Cartoes/CadastrarComToken`;
		const result = await apiService.post(
			url,
			{
				TokenCartao: cardToken,
				TipoCartao: cardType,
				Bandeira: cardBrand,
			},
			withAuthHeader(mainModule)
		);
		return result;
	};

export const login = (data) => async (dispatch, getState) => {
	try {
		const login = getState().main.login || {};

		const url = `Usuarios/Logar`;
		const dto = mapLoginCreateRequest(data);
		const result = await apiService.post(url, dto);
		const mappedResult = mapLoginCreateData(result.data);

		if (mappedResult.success) {
			const { name, accessToken, cpf } = mappedResult;

			dispatch({
				type: actionTypes.SET_LOGIN,
				payload: {
					...login,
					...mappedResult,
					success: true,
					confirmationType: "sms",
					resend: false,
				},
			});

			dispatch(user.setAccessToken(accessToken));
			dispatch(user.setUserName(name));
			dispatch(user.setUserCpf(cpf));

			return mappedResult;
		}

		return mappedResult;
	} catch (e) {
		console.log(e);

		dispatch({
			type: actionTypes.SET_LOGIN,
			payload: {
				status: false,
				userData: null,
			},
		});
	}
};

export const setLogout = () => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_LOGOUT,
		payload: null,
	});
};

export const logout = () => async (dispatch, getState) => {
	const URLParameters = getState().main.URLParameters || "";

	dispatch(user.setAccessToken(null));
	dispatch(user.setUserName(null));
	dispatch(addressActions.setAddress({}));
	dispatch(addressActions.setAddresses(null));
	dispatch(addressActions.setAddress(null));
	dispatch(addressActions.setCEP(null));
	dispatch(setUserCard(null));
	dispatch(setCart({ products: null }));
	dispatch(setUserCards(null));
	dispatch(signUp.setSignUp(null));
	dispatch(setLogout(null));

	history.push(`/${URLParameters}`);
};

export const addTicketCard = () => async (dispatch, getState) => {
	try {
		const card = getState().main && getState().main.card;

		const brandId = getCardBrand(onlyNumbers(card.number));

		if (brandId && brandId.id) {
			await dispatch(setCardBrandId(brandId.id));
		}

		const cardData = {
			...card,
			birth: card.birth.replace(/\/|-/g, ""),
			name: card.name.toUpperCase(),
			number: card.number.replace(/\s+/g, ""),
		};

		const url = `Ticket/Cadastrar`;
		const data = mapAddTicketCardRequestToApi(cardData);
		const apiResult = await apiService.post(
			url,
			data,
			withAuthHeader(mainModule)
		);
		const mappedResult = mapAddTicketCardResponse(apiResult.data);

		if (mappedResult && mappedResult.success) {
			dispatch({
				type: actionTypes.SET_SAVED_CARD,
				payload: {
					...data,
					...mappedResult,
				},
			});

			dispatch(setCard(null));

			return mappedResult;
		}

		dispatch({
			type: actionTypes.SET_SAVED_CARD,
			payload: {
				...data,
				...mappedResult,
			},
		});

		dispatch(
			setNotification({
				type: "warning",
				message: mappedResult.message,
			})
		);
	} catch (e) {
		console.log(e);

		dispatch({
			type: actionTypes.SET_SAVED_CARD,
			payload: null,
		});

		dispatch(
			setNotification({
				type: "warning",
				message: "Não foi possível cadastrar o cartão. Tente novamente.",
			})
		);
	}
};

export const postCard = () => async (dispatch, getState) => {
	try {
		const card = getState().main && getState().main.card;

		const brandId = getCardBrand(onlyNumbers(card.number));

		if (brandId && brandId.id) {
			await dispatch(setCardBrandId(brandId.id));
		}

		const date = card.date.replace(/\/|-/g, "");

		const postCard = {
			number: card.number.replace(/\s+/g, ""),
			holder_name: card.name,
			holder_document: card.CPF.replace(/\.|-/g, ""),
			exp_month: Number(date.slice(0, 2)),
			exp_year: Number(date.slice(-2)),
			cvv: card.CVV,
			brand: brandId && brandId.name,
			label: brandId && brandId.name,
		};

		const pagarmeData = await dispatch(fetchCardCreateToken(postCard));

		const pagarmeCard = await dispatch(
			postCardWithToken({
				cardBrand: brandId.id,
				cardToken: pagarmeData.cardToken,
				cardType: getCardTypeByBrand(brandId.id),
			})
		);

		const cardData = {
			digits: card.number.replace(/\s+/g, ""),
			firstDigits: pagarmeData.firstDigits,
			brandId: brandId.id,
			type: 1,
			cpf: card.CPF.replace(/\.|-/g, ""),
			expMonth: Number(date.slice(0, 2)),
			expYear: Number(date.slice(-2)),
			name: card.name,
		};
		const mappedResult = mapAddCardResponse(pagarmeCard.data);

		if (mappedResult && mappedResult.success) {
			dispatch({
				type: actionTypes.SET_SAVED_CARD,
				payload: {
					...cardData,
					...mappedResult,
				},
			});

			dispatch(setCard(null));

			return mappedResult;
		}

		dispatch({
			type: actionTypes.SET_SAVED_CARD,
			payload: {
				...cardData,
				...mappedResult,
			},
		});

		dispatch(
			setNotification({
				type: "warning",
				message: mappedResult.message,
			})
		);
	} catch (e) {
		console.log(e);

		dispatch({
			type: actionTypes.SET_SAVED_CARD,
			payload: null,
		});

		dispatch(
			setNotification({
				type: "warning",
				message: "Não foi possível cadastrar o cartão. Tente novamente.",
			})
		);
	}
};

export const deleteCard = (card) => async (dispatch) => {
	try {
		const url = `Cartoes/Excluir`;
		const dto = mapDeleteCardRequestToApi(card);
		const apiResult = await apiService.post(
			url,
			dto,
			withAuthHeader(mainModule)
		);
		const response = mapDeleteCardResponse(apiResult.data);
		if (response.success) {
			dispatch({
				type: actionTypes.SET_DELETE_CARD,
				payload: null,
			});

			return response;
		} else {
			dispatch({
				type: actionTypes.SET_DELETE_CARD,
				payload: null,
			});

			return response;
		}
	} catch (e) {
		console.log(e);
	}
};

export const postFacebookLogin = (form) => async (dispatch) => {
	try {
		const url = `Usuarios/LogarViaFacebook`;
		const data = mapFbLoginFormToAPI(form);
		const result = await apiService.post(url, data);
		const mappedResult = mapAuthAPIResponse(result.data);

		if (mappedResult.success && mappedResult.user && mappedResult.user.signUp) {
			dispatch(
				setLogin({
					...mappedResult,
					email: form.email,
					password: null,
					success: true,
				})
			);

			return mappedResult;
		}

		if (
			mappedResult.success &&
			mappedResult.user &&
			!mappedResult.user.signUp
		) {
			const { user: mappedUser } = mappedResult;

			dispatch(
				setLogin({
					...mappedResult,
					...form,
					email: form.email,
					password: null,
					success: true,
				})
			);

			dispatch(user.setUserCpf(mappedUser.cpf));

			return mappedResult;
		}

		dispatch(
			setNotification({
				type: "warning",
				message: "Não foi possível fazer login com o Facebook.",
			})
		);

		return mappedResult;
	} catch (e) {
		console.log(e);
	}
};

export const postGoogleLogin = (form) => async (dispatch) => {
	try {
		const url = `Usuarios/LogarViaGoogle`;
		const data = mapGoogleLoginFormToAPI(form);
		const result = await apiService.post(url, data);
		const mappedResult = mapAuthAPIResponse(result.data);
		if (mappedResult.success && mappedResult.user && mappedResult.user.signUp) {
			dispatch(
				setLogin({
					...mappedResult,
					email: form.email,
					password: null,
					success: true,
				})
			);

			return mappedResult;
		}

		if (
			mappedResult.success &&
			mappedResult.user &&
			!mappedResult.user.signUp
		) {
			const { user: mappedUser } = mappedResult;

			dispatch(
				setLogin({
					...mappedResult,
					...form,
					email: form.email,
					password: null,
					success: true,
				})
			);

			dispatch(user.setUserCpf(mappedUser.cpf));

			return mappedResult;
		}

		dispatch(
			setNotification({
				type: "warning",
				message:
					mappedResult.message || "Não foi possível fazer login com o Google.",
			})
		);

		return mappedResult;
	} catch (e) {
		console.log(e);
	}
};

export const postContactUsMessage = (message) => async (dispatch) => {
	try {
		const url = `FaleConosco/Enviar`;
		const response = await apiService.post(url, { Mensagem: message });
		return { success: true, response };
	} catch (e) {
		const message = "Não foi possível enviar a mensagem para o suporte.";
		dispatch(
			setNotification({
				type: "warning",
				message,
			})
		);
		return { success: false, message };
	}
};

export const postSendNewPassword = (email) => async (dispatch) => {
	try {
		const url = `Usuarios/RecuperarSenha`;
		const response = await apiService.post(url, { Email: email });
		let result = {};

		if (response && response.data) {
			result = {
				...result,
				success: response.data["Sucesso"],
				message: response.data["Mensagem"],
			};
		}

		return result;
	} catch (e) {
		const message = "Não foi possível enviar a nova senha.";
		dispatch(
			setNotification({
				type: "warning",
				message,
			})
		);
		return { success: false, message };
	}
};

export const getApplicationsSettings = () => async () => {
	try {
		const url = `aplicativos/obterconfiguracao`;
		return await apiService.get(url);
	} catch (e) {
		const message = "Não foi obter configurações.";
		console.error("main.js[getApplicationsSettings]: ", e);
		return { success: false, message };
	}
};
