import React, { useEffect, useState, useCallback } from 'react';

import { countries } from '../../../domain/enum/countries';

import LoadingGIF from '../../assets/ipiranga-loading.gif';

import Country from '../../components/common/Country';

import {
  Wrapper,
  ContentWrapper,
  LoadingWrapper,
  LoadingImage,
  Button,
  Input,
  Select,
  Paragraph,
  Form,
  Div,
  SelectWrapper,
  PhoneNumberInput,
  SelectedPhoneWrapper,
} from './styles';

const phoneRegex = /^\(\d{2}\) 9\d{4}-\d{4}$/;
const maskPhone = (value) => {
  // 11999999999 tem 11 characters
  let phone = String(value).replace(/\D/g, '').slice(0, 11);

  if (phone.length === 0) {
    return '';
  }

  return phone
    .replace(/^(\d{0,2})/, '($1')
    .replace(/^(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{5})(\d{1,4})$/, '$1-$2');
};

export function Phone(props) {
  const {
    code,
    phone,
    ddi,
    country,
    handleInput,
    confirmationType,
    messageErrors,
    time,
    handlePhoneSubmit: propsHandlePhoneSubmit,
    handleCodeSubmit: propsHandleCodeSubmit,
    step,
    handleTime,
  } = props;

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handlePhoneKeyUp = useCallback((event) => {
    handleInput({
      ...event,
      target: {
        ...event.target,
        name: 'phone',
        value: maskPhone(event.target.value),
      },
    });
  });

  const handlePhonePaste = useCallback((event) => {
    handleInput({
      ...event,
      target: {
        ...event.target,
        name: 'phone',
        value: maskPhone(event.clipboardData.getData('Text')),
      },
    });
  });

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => setIsValid(phoneRegex.test(phone)), [phone]);

  async function handlePhoneSubmit() {
    if (isValid) {
      setLoading(true);
      await propsHandlePhoneSubmit();
      setLoading(false);
    }
  }

  async function handleCodeSubmit() {
    setLoading(true);

    await propsHandleCodeSubmit();

    setLoading(false);
  }

  return (
    <Wrapper id="sign-up-phone">
      {loading && (
        <LoadingWrapper id="sign-up-loading">
          <LoadingImage src={LoadingGIF} />
        </LoadingWrapper>
      )}

      {!loading && (
        <ContentWrapper id="sign-up-content">
          <Form
            id="sign-up-phone-form"
            method="post"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            {!step ? (
              <>
                <Paragraph>
                  Digite o seu telefone celular para finalizar o seu cadastro.
                </Paragraph>

                <Div>
                  <SelectWrapper>
                    <Country url={country} />
                    <Select
                      value={`${country}|${ddi}`}
                      name="ddi"
                      onChange={handleInput}
                    >
                      {countries.map((mappedItem, index) => {
                        const key = `phone-key-${index}`;
                        const { abbr } = mappedItem;
                        const codes = abbr.split('/');

                        return (
                          <option
                            key={key}
                            value={`${mappedItem.url}|${mappedItem.ddi}`}
                          >
                            {`${codes[0]} +${mappedItem.ddi}`}
                          </option>
                        );
                      })}
                    </Select>
                  </SelectWrapper>

                  <PhoneNumberInput
                    maxLength={15}
                    name="phone"
                    placeholder="(99) 99999-9999"
                    value={phone}
                    onPaste={handlePhonePaste}
                    onKeyUp={handlePhoneKeyUp}
                    onChange={handleInput}
                  />
                </Div>

                <Button
                  onClick={() => {
                    handlePhoneSubmit();
                  }}
                  disabled={!isValid}
                >
                  Próximo
                </Button>
              </>
            ) : null}

            {step === 1 ? (
              <>
                <Paragraph>
                  Digite o código de 6 dígitos enviado por{' '}
                  <Paragraph.Bold>SMS</Paragraph.Bold> no número abaixo. Ele
                  também foi enviado para o{' '}
                  <Paragraph.Bold>EMAIL</Paragraph.Bold> cadastrado.
                </Paragraph>
                <SelectedPhoneWrapper>
                  <Country url={country} />
                  <span>
                    {`${country}  +${ddi} `} {phone}
                  </span>
                </SelectedPhoneWrapper>

                <Div>
                  <Input
                    maxLength={6}
                    name="code"
                    placeholder=""
                    value={code}
                    onKeyDown={(event) => {
                      const { keyCode } = event;

                      if (
                        !(
                          (keyCode >= 48 && keyCode <= 57) ||
                          (keyCode >= 96 && keyCode <= 105) ||
                          keyCode === 8 ||
                          keyCode === 9 ||
                          keyCode === 46 ||
                          keyCode === 37 ||
                          keyCode === 39
                        )
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleInput}
                  />
                </Div>

                <Button
                  disabled={!code || code == undefined || code.length < 5}
                  onClick={() => {
                    handleCodeSubmit();
                  }}
                >
                  OK
                </Button>

                {confirmationType === 'sms' && messageErrors + 1 <= 2 ? (
                  <Button
                    className="no-highlight"
                    disabled={time > 0 ? true : false}
                    onClick={async (event) => {
                      event.preventDefault();

                      if (time > 0) {
                        return;
                      }

                      const newConfirmationType =
                        messageErrors + 1 >= 4 ? 'phone' : 'sms';

                      handleTime(30, time, {
                        messageErrors: messageErrors + 1,
                        confirmationType: newConfirmationType,
                        resend: true,
                        clear: true,
                      });

                      handlePhoneSubmit();
                    }}
                  >
                    Reenviar código{time > 0 ? `: ${time}` : ''}
                  </Button>
                ) : (
                  <Button
                    className="no-highlight"
                    disabled={time > 0 ? true : false}
                    onClick={async (event) => {
                      event.preventDefault();

                      if (time > 0) {
                        return;
                      }

                      handleTime(30, time, {
                        messageErrors: messageErrors + 1,
                        confirmationType: 'phone',
                        resend: true,
                        clear: true,
                      });

                      handlePhoneSubmit();
                    }}
                  >
                    Receber ligação{time > 0 ? `: ${time}` : ''}
                  </Button>
                )}
              </>
            ) : null}
          </Form>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}
