import { useEffect } from 'react';
import { utmParamsUtils } from '../../infra/utils';

const getUTMParameters = () => {
	const params = new URLSearchParams(window.location.search);
	return {
		utm_source: params.get('utm_source') || '',
		utm_medium: params.get('utm_medium') || '',
		utm_campaign: params.get('utm_campaign') || '',
		utm_term: params.get('utm_term') || '',
		utm_content: params.get('utm_content') || '',
	};
};

function useUTMTracker() {
	useEffect(() => {
		const utmParams = getUTMParameters();
		utmParamsUtils.persistUTMParams(utmParams);

		if (typeof window !== 'undefined' && window.dataLayer) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'utm_params',
				...utmParams,
			});
		}
	}, []);
}

export default useUTMTracker;
