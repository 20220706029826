const localStorageKey = '@voceqpad/e-commerce/utm-params';

const persistUTMParams = (params) => {
	try {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  } catch (e) {
    console.log('localstorage persistUTMParams error ', e);
  }
};

const getUTMParams = () => {
	const params = localStorage.getItem(localStorageKey);

	if (!params) {
		return {};
	}

	try {
		return JSON.parse(params);
	} catch (e) {
		return {};
	}
};

const utmParamsUtils = { persistUTMParams, getUTMParams };

export default utmParamsUtils;
